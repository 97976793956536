import React from 'react'

import Image from 'gatsby-image'

import styles from './style.module.scss'

import cn from 'classnames'

const FeaturedImage = ({ title, src, fluid, alt }) => {
    return fluid ? (
        <div className={styles.featuredImage}>
            <Image className={styles.image} fluid={fluid} alt={alt} />
            <div className={styles.titleContainer}>
                <div className="wrapper">
                    <h1
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                </div>
            </div>
        </div>
    ) : null
}

export const FeaturedImageWithContent = ({
    title,
    src,
    fluid,
    alt,
    children,
    className
}) => {
    return fluid ? (
        <div className={styles.featuredImage}>
            <Image
                className={cn(styles.image, className)}
                fluid={fluid}
                alt={alt}
            />
            <div className={styles.titleContainer}>
                <div className="wrapper">
                    <div className="row middle-xs">
                        <div className="col-xs-12 col-sm-8">
                            <h1
                                className={styles.titleWithChildren}
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-4">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    ) : null
}

export default FeaturedImage
