import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import Projects from '../components/projects'

import FeaturedImage from '../components/featured-image'

import SeoSearch from '../components/seo-search'

import { DataCard } from '../components/card'

import { FaMapMarkerAlt } from 'react-icons/fa'

import Pagination from '../components/pagination'

export default ({ data, pageContext }) => {
    const area = data.allWordpressWpAreas.edges[0].node

    const { numPages, currentPage } = pageContext

    return (
        <Layout
            marketTicker
            breadcrumb={[
                {
                    title: 'Home',
                    link: '/'
                },
                {
                    title: 'Areas',
                    link: '/areas'
                },
                {
                    title: area.name
                }
            ]}
        >
            <SEO
                title={area.acf.meta_title}
                description={area.acf.meta_description}
            />
            <FeaturedImage
                title={area.name}
                fluid={
                    area.acf &&
                    area.acf.area_image &&
                    area.acf.area_image.localFile.childImageSharp.fluid
                }
            />
            {data.allWordpressWpProject && <Projects data={data} />}

            <div className="pageContainer">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-xs-12">
                            {numPages > 1 && (
                                <Pagination
                                    currentPage={currentPage}
                                    numPages={numPages}
                                    baseLink={`/area/${area.slug}`}
                                />
                            )}

                            <DataCard
                                label={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: 'About ' + area.name
                                        }}
                                    />
                                }
                                icon={<FaMapMarkerAlt />}
                            >
                                {area.description
                                    ? area.description
                                    : 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                            </DataCard>
                        </div>
                    </div>
                </div>
            </div>

            <SeoSearch data={data} />
        </Layout>
    )
}
export const query = graphql`
    query($slug: String!, $id: [Int!], $limit: Int!, $skip: Int!) {
        allWordpressWpAreas(filter: { slug: { eq: $slug } }) {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                    description
                    acf {
                        meta_title
                        meta_description
                        area_image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 800) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        allWordpressWpDevelopers {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                }
            }
        }

        allWordpressWpProject(
            limit: $limit
            skip: $skip
            filter: { areas: { in: $id } }
        ) {
            edges {
                node {
                    title
                    developers
                    areas
                    slug
                    acf {
                        investor_grade
                        end_user_grade
                    }
                    featured_media {
                        alt_text
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }

        allWordpressWpSeoSearch(filter: { areas: { in: $id } }) {
            edges {
                node {
                    title
                    slug
                    wordpress_id
                    seo_search_category
                    areas
                }
            }
        }

        allWordpressWpSeoSearchCategory {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                }
            }
        }
    }
`
